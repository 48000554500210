// @ts-nocheck
import {  useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../assets/images/Icon_Close.svg';
import { ReactComponent as MinimizeAppIcon } from '../assets/images/Minimize_App.svg';
import { ReactComponent as PinIcon } from '../assets/images/icon-pin.svg';
// import {ReactComponent as NotificationIcon} from '../assets/images/notification.svg';
import { ReactComponent as SettingsIcon } from '../assets/images/settings.svg';
import { ReactComponent as ActiveSettingsIcon } from '../assets/images/settings-active.svg';
import { ReactComponent as PinSettingsIcon } from '../assets/images/Pin-Active.svg';
import { ReactComponent as ChatIcon } from '../assets/images/chat.svg';
import { ReactComponent as VideoLibraryIcon } from '../assets/images/Video-Library.svg';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import {
    localStorageStickyItemKey,
    toggleStickyBtnId,
    routes,
    userRole,
} from "../../common";
import { ChatIconToolTip, pinTooltip, shareappTooltip, signupTooltip } from '../tooltip';
import { useGlobalStore, useSellerOrderStore, getChannelWindow , useAuthStore } from '@bryzos/giss-ui-library';
import { CommonTooltip } from '../component/Tooltip/tooltip';
import { navigatePage } from '../helper';
import useSnackbarStore from '../component/Snackbar/snackbarStore';
import ToastSnackbar from '../component/Snackbar/ToastSnackbar';
import clsx from 'clsx';

function handleToggleStickyButtonStyle(isSticky, toggleStickyBtn) {
    if (isSticky) {
        toggleStickyBtn.classList.add('pinFocus');
    } else {
        toggleStickyBtn.classList.remove('pinFocus');
    }
}

function Header(props) {
    const {setEnableShareWidget, userData, showChatIcon, setEmitAppCloseEvent, hasLoginProcessCompleted, isImpersonatedUserLoggedIn, setTriggerExitImpersonation , hideHeaderState , showLoader, disableBryzosNavigation, appVersion} = useGlobalStore();
    const location = useLocation();
    const {showAlertForNewPo } = useSellerOrderStore();
    const channelWindow = getChannelWindow() ;
    const {openSnackbar } = useSnackbarStore();
    const currentTandC = userData?.data?.current_tnc_version;
    const acceptedTandC = userData?.data?.accepted_terms_and_condition;
    const {initiateLogout} = useAuthStore()
    const closeBtnClick = () => {
        if(channelWindow?.close){
            setEmitAppCloseEvent(true);
            window.electron.send({ channel: channelWindow.close })
        }
    }

    const minimizeBtnClick = () => {
        if(channelWindow?.minimize){
            window.electron.send({ channel: channelWindow.minimize })
        }
    }
    const toggleAlwaysOnTop = () => {
        let isSticky;
        if(channelWindow?.sticky){
            isSticky = window.electron.sendSync({ channel: channelWindow.sticky, data: null });
        }
        // Save the isSticky value in localStorage
        sessionStorage.setItem(localStorageStickyItemKey, isSticky ?? null);

        return isSticky;
    }
    const setShowLoader = useGlobalStore(state => state.setShowLoader);


    useEffect(() => {
        const toggleStickyBtn = document.getElementById(toggleStickyBtnId);
        if (toggleStickyBtn) {
            toggleStickyBtn.addEventListener('click', () => {
                const isSticky = toggleAlwaysOnTop();
                handleToggleStickyButtonStyle(isSticky, toggleStickyBtn);
            });
        }
    }, []);

    useEffect(() => {
        const toggleStickyBtn = document.getElementById(toggleStickyBtnId);
        if (toggleStickyBtn) {
            let isSticky = JSON.parse(sessionStorage.getItem(localStorageStickyItemKey));
            handleToggleStickyButtonStyle(isSticky, toggleStickyBtn);
        }
    }, []);

    const handleGoToBuyerSettingPage = (event) => {
        if(location.pathname !== routes.buyerSettingPage && location.pathname !== routes.sellerSettingPage){
            setShowLoader(true);
            if (userData.data.type === userRole.buyerUser) {
                navigatePage(location.pathname, {path: routes.buyerSettingPage})
            } else if (userData.data.type === userRole.sellerUser) {
                navigatePage(location.pathname, {path: routes.sellerSettingPage})
            }
        }
    }

    const gotoSearchPage = () => {
        setEnableShareWidget(false);
        if (userData.data && !disableBryzosNavigation) {
            navigatePage(location.pathname, {path: routes.homePage})
            if (props.handleCloseWidget) {
                props.handleCloseWidget()
            }
        }
    }
    const gotoShareWidgetPage = () => {
        setEnableShareWidget(true);
        navigatePage(location.pathname, {path:routes.homePage, state: {enableShareWidget: true}});
    }
    const exitHandler = () => {
        setTriggerExitImpersonation(true);
    }

    if(location.pathname === routes.newUpdate){
        return <></>
    }

    return (
        <> 
            <div className={`bryzos ${location.pathname !== routes.loginPage ? 'resetPosition':'' }`}>
                <div className={'headerTitle'}
                >
                    <span className={`bryzosTitle ${(userData.data && !disableBryzosNavigation) ? 'addCursorPointer' : ''}`} onClick={gotoSearchPage}> 
                    
                        <CommonTooltip
                            title={"Click the Bryzos icon to return to Price Search screen at any time"}
                            tooltiplabel={'B R Y Z O S'}
                            placement={'bottom-start'}
                            classes={{
                                popper: 'tooltipPopper',
                                tooltip: 'tooltipMain',
                                arrow: 'tooltipArrow'
                            }}
                            localStorageKey="bryzosTextTooltip"
                        />
                    </span>

                        <CommonTooltip
                            title={'Click here to log out of your account'}
                            tooltiplabel={<span onClick={() =>initiateLogout(false, false, true)} className={`${(hasLoginProcessCompleted && userData.data) ? 'bedgeLogin' : 'bedgeLogout'}`}></span>}
                            placement={'bottom-start'}
                            classes={{
                                popper: 'tooltipPopper',
                                tooltip: 'tooltipMain tooltipMainLogout',
                                arrow: 'tooltipArrow'
                            }}
                            localStorageKey="logoutBtnTooltip"
                        />
                    
                    <span className='drag dragMain'>
                    {(import.meta.env.VITE_ENVIRONMENT === 'staging' || import.meta.env.VITE_ENVIRONMENT === 'dev') ? <span className='stagingEnv'>&nbsp;v{appVersion}</span> : 
                    (import.meta.env.VITE_ENVIRONMENT === 'qa')?<span className='qaEnv'>&nbsp; v{appVersion}</span>:
                    (import.meta.env.VITE_ENVIRONMENT === 'demo' && <span className='demoEnv'>&nbsp; v{appVersion}</span>)}
                    </span>
                    {(location.pathname === routes.loginPage && !showLoader) &&
                        <Tooltip
                            title={signupTooltip()}
                            arrow
                            placement={'left'}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            classes={{
                                tooltip: 'signupTooltip',
                            }}
                        >
                            <span className='signUpbtn' onClick={()=>{console.log("Header: Sign up button clicked.");return navigatePage(location.pathname, {path:routes.onboardingDetails})}}>Sign up</span>
                        </Tooltip>
                    }
                    {(location.pathname === routes.forgotPassword && !hasLoginProcessCompleted) &&
                        <span className='signUpbtn' onClick={()=>{console.log("Header: Login button clicked.");return navigatePage(location.pathname, {path:routes.loginPage})}}>Login</span>
                    }
                </div>
                <div className='buttoncut'>
                    {hasLoginProcessCompleted && userData.data && location.pathname !== routes.TnCPage && location.pathname !== routes.changePassword && !hideHeaderState &&
                        <>
                         {
                                location.pathname === routes.orderPage ? 
                                <CommonTooltip
                                title={'Click here to see current available orders. When "Orders" is highlighted in a green box, that means there are new orders that you have not seen yet.'}
                                tooltiplabel={<span onClick={() => navigatePage(location.pathname, {path: routes.orderPage})} className='headerOrderActive headerTextActive'>Orders</span>}
                                placement={'bottom'}
                                classes={{
                                    popper: 'tooltipPopper',
                                    tooltip: 'tooltipMain tooltipCenter',
                                    arrow: 'tooltipArrow'
                                }}
                                localStorageKey="OrderPageTooltip"
                            /> :
                                    userData.data.type === userRole.buyerUser ? '' :
                                        userData.data.type === userRole.neutralUser ? <span className='headerDisabled'>Orders</span> : 

                                        <CommonTooltip
                                        title={'Click here to see current available orders. When "Orders" is highlighted in a green box, that means there are new orders that you have not seen yet.'}
                                        tooltiplabel={<span style={{display:'inline-flex'}} onClick={() => navigatePage(location.pathname, {path: routes.orderPage})} className={`headerText ${showAlertForNewPo && 'showAlertForNewPo'}`}>Orders</span>}
                                        placement={'bottom'}
                                        classes={{
                                            popper: 'tooltipPopper',
                                            tooltip: 'tooltipMain tooltipCenter',
                                            arrow: 'tooltipArrow'
                                        }}
                                        localStorageKey="OrderPageTooltip"
                                    />
                                            
                            }
                            {
                                location.pathname === routes.createPoPage ? <span onClick={() => navigatePage(location.pathname, {path: routes.createPoPage})} className='headerTextActive'>Create PO</span> :
                                    userData.data.type === userRole.sellerUser ? '' :
                                        userData.data.type === userRole.neutralUser ? <span className='headerDisabled'>Create PO</span> :
                                            <span onClick={() => navigatePage(location.pathname, {path: routes.createPoPage})} className='headerText'>Create PO</span>
                            }
                            
                            { (import.meta.env.VITE_ENVIRONMENT === 'staging' || import.meta .env.VITE_ENVIRONMENT === 'dev') ? '' :
                                location.pathname === routes.disputePage ? <span  onClick={() => navigatePage(location.pathname, {path: routes.disputePage})} className='headerTextActive'>Disputes</span> :
                                userData.data.type === userRole.neutralUser ? <span className='headerDisabled'>Disputes</span> :
                                <span className='headerDisabled'>Disputes</span>
                                // <span  onClick={() => navigatePage(location.pathname, {path: routes.disputePage})} className='headerText'>Disputes</span>
                            }

                           { <span  onClick={() => navigatePage(location.pathname, {path: routes.videoLibrary})} className={clsx(location.pathname === routes.videoLibrary ? 'headerText videoLibraryNavigation videoLibraryActive' : 'headerText videoLibraryNavigation')}><VideoLibraryIcon/><span>Video Library</span></span>}

                            {
                                <Tooltip
                                    title={shareappTooltip()}
                                    arrow
                                    placement={'top'}
                                    disableInteractive
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    classes={{
                                        tooltip: 'shareappTooltip',
                                    }}
                                >
                                    <button onClick={gotoShareWidgetPage} className='shareButtonText'>

                                        Like Bryzos?<br /><span>Share it!</span>

                                    </button>
                                </Tooltip>
                            }
                            {
                               (!showChatIcon ?
                                <button onClick={() => navigatePage(location.pathname, {path: routes.chat})} className='headerText chatIcon' disabled={!showChatIcon}><ChatIcon /></button>
                                : (showChatIcon && ((location.pathname === routes.chat))) ? 
                                <ChatIconToolTip>
                                    <span onClick={() => navigatePage(location.pathname, {path: routes.chat})} className='headerText chatIcon chatIconActive'><ChatIcon /></span>
                                </ChatIconToolTip> :
                                <ChatIconToolTip>
                                    <span onClick={() => navigatePage(location.pathname, {path: routes.chat})} className='headerText chatIcon'><ChatIcon /></span>
                                </ChatIconToolTip>)
                            }
                           
                            <CommonTooltip
                                title={'Click here to access General Settings. Here, you can enter personal and company information, attach documents, add funding information, etc.'}
                                tooltiplabel={
                                    
                                    <span onClick={handleGoToBuyerSettingPage}>
                                    {
                                        location.pathname === routes.buyerSettingPage || location.pathname === routes.sellerSettingPage ?
                                            <ActiveSettingsIcon className='minimizeAppIcon' /> :
                                            userData.data.type === userRole.neutralUser ?
                                                <SettingsIcon className='minimizeAppIconDisabled' /> :
                                                <SettingsIcon className='minimizeAppIcon' />
                                    }
                                </span>
                                
                                }
                                placement={'bottom-end'}
                                classes={{
                                    popper: 'tooltipPopper',
                                    tooltip: 'tooltipMain tooltipRight',
                                    arrow: 'tooltipArrow'
                                }}
                                localStorageKey="settingPageTooltip"
                            />
                        </>
                    }
                    <Tooltip
                        title={pinTooltip()}
                        arrow
                        placement={'top-end'}
                        disableInteractive
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 200 }}
                        classes={{
                            popper: 'pinTooltipPopper',
                            tooltip: 'pinTooltip',
                            arrow:'arrowTooltip'
                        }}
                    >
                        <span id='toggle-sticky-btn'>
                            <PinIcon className='minimizeAppIcon pinIcon' />
                            <PinSettingsIcon className='activePIn' />
                        </span>

                    </Tooltip>

                    <CommonTooltip
                                title={'Click here to minimize this window to your task bar'}
                                tooltiplabel={<span onClick={minimizeBtnClick}><MinimizeAppIcon className='minimizeAppIcon' /></span>}
                                placement={'top-end'}
                                classes={{
                                    popper: 'tooltipPopper',
                                    tooltip: 'tooltipMain tooltipRight tooltipRight1',
                                    arrow: 'tooltipArrow'
                                }}
                                localStorageKey="miniMizeTooltip"
                            />

                    
                    <span onClick={closeBtnClick}><CloseIcon /></span>
                </div>
                {location.pathname !== routes.newUpdate && (
                    <div className={openSnackbar ? 'snackBarMarginTop' : ''}>
                        <ToastSnackbar />
                    </div>
                )}
            </div>
            {(isImpersonatedUserLoggedIn && location.pathname !== routes.successPage) && <span className='impersonateDetail'><span className='message'>IMPERSONATED USER -  {userData?.data?.email_id} {(currentTandC !== acceptedTandC || currentTandC === null || acceptedTandC === null) && '(TnC not accepted)'}</span><span className='ExitImpersonation' onClick={exitHandler}>Exit</span></span>}
        </>
    );
}

export default Header;
