// @ts-nocheck
import { encryptData, getChannelWindow, useGlobalStore } from "@bryzos/giss-ui-library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import axios from "axios";
import { reactQueryKeys } from "src/common";
import useSaveLoginActivityAnalytics from "./useSaveLoginActivityAnalytics";
import { getUserAppData, stringifyError } from "../helper";

const authSignIn = async(payload) => {
  const store = useGlobalStore.getState();
  try{
    return await Auth.signIn(payload?.email, payload?.password)
  }catch (error) {
    if (error.code === 'ERR_NETWORK_CHANGED' || error.message.includes('Network')) {
      store.setApiFailureDueToNoInternet(true);
    } else {
      throw error;
    }

  }
}

const useOnSubmitLogin = (setError) => {
  const store = useGlobalStore.getState();
  const saveLoginActivityAnalytics = useSaveLoginActivityAnalytics()
  const query = useQueryClient()
  return useMutation(async (payload) => {
    try {
      const channelWindow = getChannelWindow();
      const user = await authSignIn(payload);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(user, payload?.password);
      }
      if (channelWindow?.setLoginCredential) {
        const _encryptCredential = await encryptData(JSON.stringify(payload), store.decryptionEntity.decryption_key);
        window.electron.send({ channel: channelWindow.setLoginCredential, data: _encryptCredential });
      }
      store.setIsUserLoggedIn(true);
      store.setCurrentAccessToken(user?.signInUserSession.accessToken.jwtToken);
      query.invalidateQueries([reactQueryKeys.cognitoUser]);
    } catch (error) {
      const userAppData = getUserAppData()
      const data = {
      ...userAppData, reason:stringifyError(error) , email:payload.email
      }
      saveLoginActivityAnalytics.mutate(data)
      console.error('useOnSubmitLogin ', error)
      if (error.name === 'NotAuthorizedException')
        setError('Wrong username or password')
      store.setShowLoader(false);
    } finally {
      store.setSignupUser(null);
    }
  },
    {
      retry: 4,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 0
    }
  );
};

export default useOnSubmitLogin;
