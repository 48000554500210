// @ts-nocheck
import axios from "axios";
import { saveAs } from 'file-saver';
import rg4js from "raygun4js";
import { commomKeys, routes } from "../common";
import { Auth } from 'aws-amplify';
import { ReferenceDataProduct } from "./types/ReferenceDataProduct";

export const VERSION_NUMBER = "1.0.0.0";
export const commonRaygunError = "common-raygun-error";
export const trueVaultRaygunError = "trueVault-raygun-error";
export const unhandledError = "unhandled-error";
export const pdfGenreationError = "pdf-generation-error";

export const SEND_INVOICES_TO = "invoices@bryzos.com";
export const SHIPPING_DOCS_TO = "shippingdocs@bryzos.com";

export const mainConfig = {
  log: {
    shareLogSize: 0
  } 
};

let channelWindow = {}
let navigate = null;
let userAppData = null;
export const setChannelWindow = (channelWindowList: {}) => {
  channelWindow = channelWindowList
}

export const getChannelWindow = () => {
  return channelWindow;
}

export const setNavigate = (_navigate) => {
  if(navigate === null){
    navigate = _navigate;
  }
}

export const navigatePage = (currentPage, nextPage, extraData = null) => {
  let replaceHistory = false;

  if (extraData && extraData.notificationClick &&
    ((extraData.currentPage === routes.orderConfirmationPage && extraData.nextPage === routes.buyerSettingPage) ||
      (extraData.currentPage === routes.acceptOrderPage && extraData.nextPage === routes.orderPage))
  ) {
    replaceHistory = true;
  }

  if(currentPage === routes.orderConfirmationPageSeller 
    || currentPage === routes.acceptOrderPage
    || currentPage === routes.orderConfirmationPage
    || replaceHistory
    ){
    navigate(nextPage.path,{ ...nextPage.state ,replace: true})
  }else{
    navigate(nextPage.path, nextPage.state)
  }
}
 
// Format a number as a currency value with a dollar sign
export function formatCurrency(number) {
  return Number(number).toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).replace("$", "$ ");
}

// Format a number with commas without dollar sign
export function formatNumber(number: number) {
  return number.toLocaleString('en-US');
}

export const formatToTwoDecimalPlaces = (
  value?: string
) => {
  if (value && +value) {
   return Number(value).toLocaleString('en-US',{
    style:'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
   });
  } else {
    return '0.00';
  }
};

export const formatPhoneNumberRemovingCountryCode = (phoneNo: string)=>{
  if(phoneNo)
  return formatPhoneNumber( phoneNo.replace(commomKeys.countryCode,'') );
  return phoneNo;
}

export const formatPhoneNumber = (phoneNo: string) => {
  const phoneNumber = phoneNo.replace(/\D/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) {
    return phoneNumber;
  } else if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};

export const formatPhoneNumberWithCountryCode = (phoneNo: string) => {
    return commomKeys.countryCode + phoneNo.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
};

export const addWorkingDaysToDate = (days: number) => {
  let date = new Date();
  let count = 0;

  while (count < days) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      count++;
    }
  }
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(2)}`;
};

export const formatEIN = (value: string) => {
  const ein = value.replace(/\D/g, '');
  const einLength = ein.length;
  if (einLength < 3) {
    return ein;
  } else {
    const firstPart = ein.slice(0, 2);
    const secondPart = ein.slice(2, 9);
    return `${firstPart}-${secondPart}`;
  }
};


export async function downloadFilesUsingFetch(url: RequestInfo | URL, fileName: string | undefined, type: any) {
  try {
    const response = await fetch(url);
    const responseData = await response.blob();

    if(responseData.type === 'application/json'){
      return false;
    }
    const blob = new Blob([responseData], { type: type });
    saveAs(blob, fileName);
    return true;
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
}

export async function downloadFiles(url: string, fileName: string | undefined, type: any) {
  try {
    const response = await axios.get(url,{
      responseType: 'blob'
    });

    if(response.data.type === 'application/json'){
      return false;
    }
    const blob = new Blob([response.data], { type: type });
    saveAs(blob, fileName);
    return true;
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
}

export const downloadFileWithAnyExtension = async (url: string) => {
  let index = url.length-1;
  for(  ;index >= 0 ;index-- ){
  if(url.charAt(index)==='/'){
      break;
  }
  }
  let fileName = url.substring(index+1, url.length);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const dispatchRaygunError = (error: unknown, tags: string | string[]) => {
  rg4js('send', {
    error: error,
    tags: tags
  })
}

export const formatCurrencyWithComma = (value: string) => {
  if (value) {
    return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
};

export const removeCommaFromCurrency = (value: string) => {
  if (value) {
    return value.replace(/\,/g, "");
  } else {
    return "";
  }
};

export const getFloatRemainder = (dividend: string | number | null, divisor: number) => {
  if (dividend && +divisor) {
    dividend = +dividend;
    divisor = +divisor;

    var dividendDecimalCount = (dividend.toString().split('.')[1] ?? '').length;
    var divisorDecimalCount = (divisor.toString().split('.')[1] ?? '').length;
    var decimalCount = dividendDecimalCount > divisorDecimalCount ? dividendDecimalCount : divisorDecimalCount;

    var dividendInt = parseInt(dividend.toFixed(decimalCount).replace('.', ''));
    var divisorInt = parseInt(divisor.toFixed(decimalCount).replace('.', ''));

    return (dividendInt % divisorInt) / Math.pow(10, decimalCount);
  } else {
    throw new Error("dividend or divisor value is invalid");
  }
}

export const get2DigitFormater = () => {
  return  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
}

export const get4DigitFormater = () => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
});
}

export const format4DigitAmount = (value: string | number) => {
  const decimal4Formater = get4DigitFormater();
  const _value = +value ? +value : 0;
  return decimal4Formater.format(_value).replace("$", "");
};

export const format2DigitAmount = (value: string | number) => {
  const decimal2Formater = get2DigitFormater();
  const _value = +value ? +value : 0;
  return decimal2Formater.format(_value).replace("$", "");
};

export function getNumericVersion(version: string | undefined)
{
  if (version && typeof version === "string") {
    return Number(version.split(".").join(""));
  }
  return false; 
}

export async function getAccessToken() {
  const user = await Auth.currentSession();
  const accessToken = user.getAccessToken();
  const jwt = accessToken.getJwtToken();
  return jwt;
}

export const getUnitPostScript = (unit) => {
  if (unit.toLowerCase() === 'cwt') {
      return "CWT"
  } else if (unit.toLowerCase() === 'ea') {
      return "Ea"
  } else if (unit.toLowerCase() === 'ft') {
      return "Ft"
  } else if (unit.toLowerCase() === 'lb') {
      return "LB"
  } else if (unit.toLowerCase() === 'net ton') {
      return "Net_Ton"
  }
}

export const getProductMapping = (productList: ReferenceDataProduct[], userData) => {
  const productMap = {};
  productList.forEach((product) => {
    product.Buyer_Pricing_CWT = product.Actual_Buyer_Pricing_CWT;
    product.Buyer_Pricing_Ea = product.Actual_Buyer_Pricing_Ea;
    product.Buyer_Pricing_Ft = product.Actual_Buyer_Pricing_Ft;
    product.Buyer_Pricing_LB = product.Actual_Buyer_Pricing_LB;
    product.Buyer_Pricing_Net_Ton = product.Actual_Buyer_Pricing_Net_Ton;

    product.Seller_Pricing_CWT = product.Actual_Seller_Pricing_CWT;
    product.Seller_Pricing_Ea = product.Actual_Seller_Pricing_Ea;
    product.Seller_Pricing_Ft = product.Actual_Seller_Pricing_Ft;
    product.Seller_Pricing_LB = product.Actual_Seller_Pricing_LB;
    product.Seller_Pricing_Net_Ton = product.Actual_Seller_Pricing_Net_Ton;

    product.Neutral_Pricing_CWT = product.Actual_Neutral_Pricing_CWT;
    product.Neutral_Pricing_Ea = product.Actual_Neutral_Pricing_Ea;
    product.Neutral_Pricing_Ft = product.Actual_Neutral_Pricing_Ft;
    product.Neutral_Pricing_LB = product.Actual_Neutral_Pricing_LB;
    product.Neutral_Pricing_Net_Ton = product.Actual_Neutral_Pricing_Net_Ton;

    if (userData.disc_is_discounted && !product.is_safe_product_code) {
      product.Buyer_Pricing_CWT = "$" + (product[userData.disc_discount_pricing_column + '_CWT'].trim().replace(/[\$,]/g, '') * userData.disc_discount_rate);
      product.Buyer_Pricing_Ea = "$" + (product[userData.disc_discount_pricing_column + '_Ea'].trim().replace(/[\$,]/g, '') * userData.disc_discount_rate);
      product.Buyer_Pricing_Ft = "$" + (product[userData.disc_discount_pricing_column + '_Ft'].trim().replace(/[\$,]/g, '') * userData.disc_discount_rate);
      product.Buyer_Pricing_LB = "$" + (product[userData.disc_discount_pricing_column + '_LB'].trim().replace(/[\$,]/g, '') * userData.disc_discount_rate);
      product.Buyer_Pricing_Net_Ton = "$" + (product[userData.disc_discount_pricing_column + '_Net_Ton'].trim().replace(/[\$,]/g, '') * userData.disc_discount_rate);

    }
    productMap[product.Product_ID] = product;
  });
  return productMap;
}

export const updatedAllProductsData = (products: ReferenceDataProduct[]) => {
  if (products?.length) {
    return products.map(product => ({
      ...product,
      Actual_Buyer_Pricing_CWT: product.Buyer_Pricing_CWT,
      Actual_Buyer_Pricing_Ea: product.Buyer_Pricing_Ea,
      Actual_Buyer_Pricing_Ft: product.Buyer_Pricing_Ft,
      Actual_Buyer_Pricing_LB: product.Buyer_Pricing_LB,
      Actual_Buyer_Pricing_Net_Ton: product.Buyer_Pricing_Net_Ton,

      Actual_Seller_Pricing_CWT: product.Seller_Pricing_CWT,
      Actual_Seller_Pricing_Ea: product.Seller_Pricing_Ea,
      Actual_Seller_Pricing_Ft: product.Seller_Pricing_Ft,
      Actual_Seller_Pricing_LB: product.Seller_Pricing_LB,
      Actual_Seller_Pricing_Net_Ton: product.Seller_Pricing_Net_Ton,

      Actual_Neutral_Pricing_CWT: product.Neutral_Pricing_CWT,
      Actual_Neutral_Pricing_Ea: product.Neutral_Pricing_Ea,
      Actual_Neutral_Pricing_Ft: product.Neutral_Pricing_Ft,
      Actual_Neutral_Pricing_LB: product.Neutral_Pricing_LB,
      Actual_Neutral_Pricing_Net_Ton: product.Neutral_Pricing_Net_Ton,
    }));
  }
  return products;
}

export const  getUserAppData = () => userAppData
export const  setUserAppData = (data) => { 
  userAppData = data 
}

export const stringifyError = (err) => {
  let lastTwoTraces
  if (err.stack) {
    const stackLines = err.stack.split("\n");
    lastTwoTraces = stackLines.slice(0, 3).join("\n"); // First line is error message, next two are traces
  }
  const errorJson = {
    name: err.name,
    message: err.message,
    stack: lastTwoTraces
  };
  return errorJson
}